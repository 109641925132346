import React, { useMemo } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import favicon from "../static/images/favicon.png";
import { useI18next } from "gatsby-plugin-react-i18next";
import { graphql, Link } from "gatsby";
import CookieConsent from "react-cookie-consent";
import Button from "../components/common/Button";

const Layout = ({ children, title = "Connected Life" }) => {
  const { t } = useI18next();
  const ttitle = useMemo(() => t(title), [t, title]);

  return (
    <>
      <Helmet>
        <title>{ttitle} - Your IoT solution | Connected Life</title>
        <link rel="icon" type="image/png" href={favicon} />
        <meta name="description" content={t("index.hero1")} />
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText={t("general.accept")}
        declineButtonText={t("general.decline")}
        cookieName="gatsby-gdpr-google-analytics"
        buttonWrapperClasses="flex items-center"
        ButtonComponent={Button}
        buttonStyle={{ borderRadius: ".5rem" }}
        declineButtonStyle={{ borderRadius: ".5rem" }}
      >
        {t("general.cookieText")}{" "}
        <Link className="text-blue-400" to="cookies">
          {t("general.cookiePolicy")}
        </Link>
      </CookieConsent>
    </>
  );
};

export default Layout;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
