import React from "react";

const SubFooter = () => {
  return (
    <div className="bg-dark py-3 text-sm text-center text-light">
      Made with 💛 in Poland | Connected Life &copy; 2022
    </div>
  );
};

export default SubFooter;
