import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import Section from "../common/Section";

const Cta = () => {
  const { t } = useI18next();

  return (
    <div className="stripe-bg py-1 relative">
      <section className="container mx-auto px-6 my-10 lg:my-20 flex flex-col gap-8 md:gap-16 md:flex-row lg:justify-center items-center">
        <h2 className="font-bold text-text-primary text-2xl md:text-4xl lg:text-6xl">
          Let's stay Connected
        </h2>
        {/* <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="flex flex-col gap-2 w-full md:w-auto"
        >
          <label
            htmlFor="email"
            className="text-sm md:text-base text-text-primary"
          >
            {t("general.email")}
          </label>
          <div className="flex rounded-lg">
            <input
              type="email"
              name="email"
              className="p-1 rounded-l-lg text-lg flex-shrink min-w-0 flex-grow lg:flex-grow-0"
            />
            <button
              className="px-4 py-2 rounded-r-lg transition-colors bg-text-primary hover:bg-dark text-lg text-white"
              type="submit"
            >
              {t("general.send")}
            </button>
          </div>
          <small className="text-xs text-text-primary text-opacity-90">
            {t("general.agreement1")}
            <strong>{t("general.tos")}</strong> {t("general.and")}{" "}
            <strong>{t("general.policy")}</strong>.
          </small>
        </form> */}
      </section>
      <div className="absolute bottom-0 left-0 w-full overflow-hidden leading-[0]">
        <svg
          dataName="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
          className="relative block w-full h-4 md:h-8 lg:h-14"
        >
          <path
            d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
            className="fill-text-primary"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Cta;
