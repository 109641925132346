import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";

const Button = ({
  as: Component = "button",
  to,
  onClick,
  outline,
  children,
  className,
  ...rest
}) => {
  const { language } = useI18next();

  return (
    <Component
      className={`${
        outline
          ? "border-2 border-primary hover:bg-primary dark:text-light dark:hover:text-text-primary"
          : "bg-primary hover:bg-primary-light hover:text-text-primary"
      } cursor-pointer text-text-primary px-4 py-2 md:px-8 text-sm md:text-base font-bold rounded-lg text-center flex items-center gap-3 justify-center transition-colors ${className}`}
      to={to ? (Component === "button" ? `/${language}${to}` : to) : "#"}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Button;
