import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Button from "../common/Button";
import Navigation from "./components/Navigation";
import LogoWhite from "../../static/svg/ConnectedLifeWhite.inline.svg";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(
    typeof window !== "undefined" ? window.scrollY > 200 : true
  );

  const stickNavbar = () => {
    if (window !== undefined) {
      window.scrollY > 200 ? setIsSticky(true) : setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <header
      className={`${
        isSticky ? "bg-white dark:bg-dark-accent shadow-xl rounded-xl" : ""
      }  fixed transition-all z-[1001] container mx-auto my-4 px-6 py-2 left-0 right-0 flex justify-between items-center gap-8 h-12 md:h-16`}
    >
      <Link
        to="/"
        className={`flex items-center min-w-0 h-4/5 ${
          isSticky
            ? "text-dark dark:text-light"
            : "text-dark-accent dark:text-dark-accent"
        }`}
      >
        <LogoWhite />
      </Link>
      <Button
        onClick={() =>
          setIsOpen((prev) => {
            console.log(prev);
            return !prev;
          })
        }
        className="lg:hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </Button>
      <Navigation isOpen={isOpen} setIsOpen={setIsOpen} isSticky={isSticky} />
    </header>
  );
};

export default Header;
