import React from "react";
import Loadable from "@loadable/component";
import NavItem from "./NavItem";
import Button from "../../common/Button";
import { Helmet } from "react-helmet";
import EuFlag from "../../../static/svg/europeanUnion.inline.svg";
import NavDropdown from "./NavDropdown";
import LanguageMenu from "./LanguageSwitch";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Link as GatsbyLink } from "gatsby";
const ThemeSwitch = Loadable(() => import("../../common/ThemeSwitch"));

const productItems = [
  {
    label: "Connected Agro",
    to: "/project",
    component: GatsbyLink,
  },
  {
    label: "Connected City",
    component: "a",
    href: "https://connectedcity.pl",
    target: "_blank",
    rel: "external",
  },
  {
    label: "Connected Property",
    to: "/products/connectedproperty",
    component: GatsbyLink,
  },
  {
    label: "Connected Environment",
    to: "/products/connectedenvironment",
    component: GatsbyLink,
  },
];

const Navigation = ({ isOpen, setIsOpen, isSticky }) => {
  const { t, language } = useI18next();

  return (
    <>
      <Helmet>
        <body className={`${isOpen ? "max-h-screen overflow-y-hidden" : ""}`} />
      </Helmet>
      <nav
        className={`flex justify-between items-start fixed -top-4 left-0 p-8 lg:p-0 w-3/4 h-screen bg-light dark:bg-dark transform lg:static lg:transform-none lg:bg-transparent dark:lg:bg-transparent lg:w-auto lg:h-auto transition-transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <ul
          className={`flex flex-col gap-6 text-lg lg:flex-row lg:items-center ${
            isSticky
              ? "text-text-primary dark:lg:text-text-primary-dark"
              : "navitem-hero"
          }`}
        >
          <NavItem
            to={`/about`}
            activeClass="before:absolute before:inset-x-0 before:top-full before:h-[2px] before:rounded-xl before:bg-primary before:transition-transform before:origin-bottom before:transform before:scale-x-100"
          >
            {t("general.company")}
          </NavItem>
          <NavDropdown label={t("general.products")} items={productItems} />
          <NavItem setIsOpen={setIsOpen} to="/map">
            {t("general.map")}
            {/* <span className="absolute right-0 top-0 transform translate-x-1/2 -translate-y-1 bg-yellow-100 text-yellow-800 text-xs font-bold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">
              Soon
            </span> */}
          </NavItem>
          <NavItem setIsOpen={setIsOpen} to="/project" highlightOnHover={false}>
            <EuFlag />
          </NavItem>
          <Button
            as={GatsbyLink}
            to={`/${language}/#products`}
            spy
            smooth
            duration={500}
          >
            {t("general.getStarted")}
          </Button>
          <ThemeSwitch />
          <LanguageMenu />
        </ul>
        <button
          onClick={() => setIsOpen(false)}
          className={`appearance-none p-2 text-text-primary lg:hidden`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </nav>
    </>
  );
};

export default Navigation;
