import React from "react";

const Section = ({
  className,
  children,
  as: Component = "section",
  ...props
}) => {
  return (
    <Component
      {...props}
      className={`container mx-auto px-6 my-10 lg:my-32 ${className}`}
    >
      {children}
    </Component>
  );
};

export default Section;
