import React, { useContext, useState } from "react";
import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next";
import en from "../../../static/svg/en.svg";
import pl from "../../../static/svg/pl.svg";
import { useClickOutside } from "../../../utils/useClickOutside";

const flags = {
  en: en,
  pl: pl,
};

const LanguageMenu = () => {
  const { changeLanguage } = useI18next();
  const { language } = useContext(I18nextContext);
  const [isOpen, setIsOpen] = useState();
  const toggleMenu = () => setIsOpen((prev) => !prev);
  const ref = useClickOutside(() => setIsOpen(false));

  const handleChange = (language) => () => {
    changeLanguage(language);
  };

  return (
    <div className="relative inline-block text-left d-flex items-center">
      <button
        onClick={toggleMenu}
        type="button"
        className="inline-flex justify-center w-full rounded-md shadow-sm p-1 bg-gray-300 dark:bg-dark text-sm font-medium text-gray-700 hover:bg-gray-400 dark:hover:bg-dark-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        <img className="w-6" src={flags[language]} alt={language} />
      </button>

      {isOpen && (
        <div
          ref={ref}
          className="origin-top-right absolute right-0 mt-2 w-16 rounded-md shadow-lg bg-gray-300 dark:bg-dark ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          {Object.entries(flags).map((item) => (
            <div className="py-1" role="none">
              <button
                onClick={handleChange(item[0])}
                className="text-gray-700 px-4 py-2 text-sm w-full flex justify-center"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
                aria-label={item[0]}
              >
                <img className="w-6" src={item[1]} alt={item[0]} />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageMenu;
