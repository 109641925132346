import React from "react";
import { Link } from "gatsby";

const SocialIcon = ({ to, children }) => {
  return (
    <Link
      to={to}
      target="_blank"
      rel="external"
      className="text-primary hover:brightness-110 active:text-gray-600 transition duration-100"
    >
      {children}
    </Link>
  );
};

export default SocialIcon;
