import React from "react";
import { Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const isActive = ({ isCurrent }) =>
  isCurrent
    ? {
        className:
          "block font-bold py-4 lg:px-3 lg:py-2 relative cursor-pointer before:absolute before:inset-x-0 before:top-full before:h-[2px] before:rounded-xl before:bg-primary",
      }
    : {};

const NavItem = ({
  to,
  children,
  highlightOnHover = "true",
  as: Component = Link,
  ...props
}) => {
  const { language } = useI18next();

  return (
    <li className="relative">
      <Component
        {...props}
        className={`block font-bold py-4 lg:px-3 lg:py-2 relative cursor-pointer ${
          highlightOnHover && to
            ? "before:absolute before:inset-x-0 before:top-full before:h-[2px] before:rounded-xl before:bg-primary before:transition-transform before:origin-right before:transform before:scale-x-0 hover:before:scale-x-100 hover:before:origin-left"
            : ""
        }`}
        to={to ? `/${language}${to}` : null}
        getProps={isActive}
      >
        {children}
      </Component>
    </li>
  );
};

export default NavItem;
