import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "gatsby";
import NavItem from "./NavItem";
import { HiChevronDown } from "react-icons/hi";

const isActive = ({ isCurrent }) =>
  isCurrent
    ? {
        className:
          "block py-4 px-3 relative cursor-pointer text-sm text-primary",
      }
    : {};

const NavDropdown = ({ label, items }) => {
  return (
    <Menu as={NavItem}>
      <Menu.Button className="flex items-center font-bold py-4 lg:px-3 lg:py-2 relative cursor-pointer">
        {label}
        <HiChevronDown color="currentColor" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="block lg:absolute left-0 lg:right-0 w-56 mt-2 origin-top-right lg:bg-light lg:dark:bg-dark-accent divide-y divide-gray-300 dark:divide-dark/80 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          {items.map(({ label, component: Component, ...props }) => (
            <Menu.Item>
              {({ active }) => (
                <Component
                  getProps={isActive}
                  key={props.to}
                  className={`block py-4 px-3 relative cursor-pointer  text-sm ${
                    active
                      ? "text-primary"
                      : "text-text-primary dark:text-text-primary-dark"
                  }`}
                  {...props}
                >
                  {label}
                </Component>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NavDropdown;
