import React from "react";
import { Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const FooterNavColumn = ({ label, items }) => {
  const { language } = useI18next();
  return (
    <div>
      <div className="text-white font-bold tracking-widest uppercase mb-4">
        {label}
      </div>

      <nav className="flex flex-col gap-4">
        {items.map((item) => (
          <div key={item.id}>
            <Link
              to={item.url ? `/${language}${item.url}` : "#"}
              className="text-xs md:text-sm text-light hover:text-primary active:text-primary transition duration-100"
            >
              {item.label}
            </Link>
          </div>
        ))}
      </nav>
    </div>
  );
};

export default FooterNavColumn;
